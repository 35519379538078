<template>
  <div
    class="popup_wrap"
    style="width:900px;"
  >
    <!-- popup_wrap 팝업사이즈 width:800px; height:540px; -->
    <button
      class="layer_close"
      @click.prevent="$emit('close')"
    >
      {{ $t('msg.CMBK100_M1.038') }}
    </button> <!-- Close -->

    <div class="popup_cont">
      <h1 class="page_title">POD INFORMATION( A/N 発行場所 / CY )</h1>
      <div class="content_box mt10">
        <!-- content_box -->
        <template>
          <h2 class="content_title"></h2>
          <table class="tbl_row">
            <colgroup>
              <col style="width:20%">
              <col style="width:30%">
              <col style="width:20%">
              <col style="width:30%">
            </colgroup>
            <tbody v-if="trackingInfoJPListFull.length > 0">
              <tr v-for="(item, idx) in trackingInfoJPListFull" :key="idx">
                <th>{{ item? item.closInfNm : '' }}</th>
                <td colspan="3"><pre class="font13">{{ item? item.closInfVar : '' }}</pre></td>
              </tr>
              <tr>
                <th>{{ trackingInfoJPList[0]? trackingInfoJPList[0].closInfNm : '' }}</th>
                <td><pre class="font13">{{ trackingInfoJPList[0]? trackingInfoJPList[0].closInfVar : '' }}</pre></td>

                <th>{{ trackingInfoJPList[1]? trackingInfoJPList[1].closInfNm : '' }}</th>
                <td><pre class="font13">{{ trackingInfoJPList[1]? trackingInfoJPList[1].closInfVar : '' }}</pre></td>
              </tr>
            </tbody>
            <tbody v-else-if="trackingInfoJPListFull.length === 0 && trackingInfoJPList.length > 0">
              <tr>
                <th>{{ trackingInfoJPList[0]? trackingInfoJPList[0].closInfNm : '' }}</th>
                <td><pre class="font13">{{ trackingInfoJPList[0]? trackingInfoJPList[0].closInfVar : '' }}</pre></td>

                <th>{{ trackingInfoJPList[1]? trackingInfoJPList[1].closInfNm : '' }}</th>
                <td><pre class="font13">{{ trackingInfoJPList[1]? trackingInfoJPList[1].closInfVar : '' }}</pre></td>
              </tr>
              <tr>
                <th>{{ trackingInfoJPList[2]? trackingInfoJPList[2].closInfNm : '' }}</th>
                <td colspan="3"><pre class="font13">{{ trackingInfoJPList[2]? trackingInfoJPList[2].closInfVar : '' }}</pre></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4">{{ $t('msg.CSCT060G030.022') }}</td><!-- No Data. -->
              </tr>
            </tbody>
          </table>
        </template>
      </div><!-- content_box // -->
    </div>
  </div>
</template>

<script>

import importSvc from '@/api/rest/trans/importSvc'

export default {
  name: 'CargoTrackingJpPodInfoPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          podPortCd: '',
          vslCd: ''
        }
      }
     }
  },
  data () {
    return {
      blNo: '',
      doStsCd: '',
      deadlineInfoDetail: {},
      importManagerDetail: {},
      importNoneManagerDetail: {},
      podPortManager: {},
      podCtrManager: {},
      trackingInfoJPList: [],
      trackingInfoJPListFull: []
    }
  },
  created () {
    console.log('created')
  },
  mounted () {
    console.log('mounted')
    this.blNo = this.parentInfo.blNo

    // JP 일때, WEB INFO TRACKING 정보 검색
    this.getTrackingInfo()
  },
  methods: {
    getTrackingInfo () {
      const TH = this
      const podPortCd = this.parentInfo.podPortCd
      const podTrmlCd = this.parentInfo.podTrmlCd
      const vslCd = this.parentInfo.vslCd

      const params = {
          podPortCd,
          podTrmlCd,
          vslCd
      }

      // JP 일때, WEB INFO TRACKING 정보 검색
      importSvc.getTrackingInfo(params)
        .then(res => {
          // console.log('getTrackingInfo @@@@@ ', res)

          const trackingInfoJPList = res.data

          for (var item of trackingInfoJPList) {
            if (item.podPortCd === podPortCd) {
              if (item.rowCd === '01') {
                TH.trackingInfoJPListFull.push(item)
              } else {
                TH.trackingInfoJPList.push(item)
              }

              TH.trackingInfoJPList = this.$_.sortBy(TH.trackingInfoJPList, ['seq'])
            }
          }
        })
    }
  }
}
</script>

<style scoped>
font13 {
  font-size:13px;
}
</style>

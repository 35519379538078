var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "900px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("msg.CMBK100_M1.038")) + " ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v("POD INFORMATION( A/N 発行場所 / CY )"),
        ]),
        _c(
          "div",
          { staticClass: "content_box mt10" },
          [
            [
              _c("h2", { staticClass: "content_title" }),
              _c("table", { staticClass: "tbl_row" }, [
                _vm._m(0),
                _vm.trackingInfoJPListFull.length > 0
                  ? _c(
                      "tbody",
                      [
                        _vm._l(
                          _vm.trackingInfoJPListFull,
                          function (item, idx) {
                            return _c("tr", { key: idx }, [
                              _c("th", [
                                _vm._v(_vm._s(item ? item.closInfNm : "")),
                              ]),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _c("pre", { staticClass: "font13" }, [
                                  _vm._v(_vm._s(item ? item.closInfVar : "")),
                                ]),
                              ]),
                            ])
                          }
                        ),
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.trackingInfoJPList[0]
                                  ? _vm.trackingInfoJPList[0].closInfNm
                                  : ""
                              )
                            ),
                          ]),
                          _c("td", [
                            _c("pre", { staticClass: "font13" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trackingInfoJPList[0]
                                    ? _vm.trackingInfoJPList[0].closInfVar
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.trackingInfoJPList[1]
                                  ? _vm.trackingInfoJPList[1].closInfNm
                                  : ""
                              )
                            ),
                          ]),
                          _c("td", [
                            _c("pre", { staticClass: "font13" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trackingInfoJPList[1]
                                    ? _vm.trackingInfoJPList[1].closInfVar
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    )
                  : _vm.trackingInfoJPListFull.length === 0 &&
                    _vm.trackingInfoJPList.length > 0
                  ? _c("tbody", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.trackingInfoJPList[0]
                                ? _vm.trackingInfoJPList[0].closInfNm
                                : ""
                            )
                          ),
                        ]),
                        _c("td", [
                          _c("pre", { staticClass: "font13" }, [
                            _vm._v(
                              _vm._s(
                                _vm.trackingInfoJPList[0]
                                  ? _vm.trackingInfoJPList[0].closInfVar
                                  : ""
                              )
                            ),
                          ]),
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.trackingInfoJPList[1]
                                ? _vm.trackingInfoJPList[1].closInfNm
                                : ""
                            )
                          ),
                        ]),
                        _c("td", [
                          _c("pre", { staticClass: "font13" }, [
                            _vm._v(
                              _vm._s(
                                _vm.trackingInfoJPList[1]
                                  ? _vm.trackingInfoJPList[1].closInfVar
                                  : ""
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.trackingInfoJPList[2]
                                ? _vm.trackingInfoJPList[2].closInfNm
                                : ""
                            )
                          ),
                        ]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c("pre", { staticClass: "font13" }, [
                            _vm._v(
                              _vm._s(
                                _vm.trackingInfoJPList[2]
                                  ? _vm.trackingInfoJPList[2].closInfVar
                                  : ""
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "4" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSCT060G030.022"))),
                        ]),
                      ]),
                    ]),
              ]),
            ],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }